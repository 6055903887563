p {
  font-size: 16px;
}
.nvadr {
  text-align: center;
  padding: 10px;
  margin-top: 100px;
}
.nvadr img {
  width: 90%;
}
.billing_text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.billing_heading {
  font-size: 50px;
  font-weight: 100;
  color: var(--color-text);
  line-height: 51px;
  text-align: center;
  font-family: 'Helvetica Neue', sans-serif !important;
}

.homepage {
  width: 100%;
  padding-top: 60px;
}
.redhunt_logo {
  width: 150px;
  margin-bottom: 30px;
}
.homepage__btn {
  display: flex;
  justify-content: space-between;
  padding: 10px 35px;
  margin: 100px 0;
}
.tagline_heading {
  font-size: 20px;
  font-weight: 700;
  color: var(--color-primary);
  margin: 10px 0;
}
.homepage_heading {
  font-size: 50px;
  font-weight: 100;
  color: #fff;
  margin-top: 100px;
  line-height: 70px;
  text-align: center;
  font-family: 'Helvetica Neue', sans-serif !important;
}
.homepage_sub_heading {
  font-size: 75px;
  font-weight: 100;
  color: #fff;
  line-height: 85px;
  text-align: center;
  font-family: 'Helvetica Neue', sans-serif !important;
}
.border-span {
  width: 150px;
  margin-left: 150px;
  border-top: 1px solid #000;
}
.homepage_info {
  transition: all 0.5s;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  font-family: 'Helvetica Neue', sans-serif !important;
  color: #fff;
}

.tagline_info {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text);
}

.dashboard_card_container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: 28px;
  margin-top: 0;
  justify-content: space-between;
}
.dashboard_card {
  background: white;
  flex: 1;
  min-height: 64px;
  /* border: 1px solid var(--color-stroke); */
  /* border-radius: 8px;
  box-shadow: var(--dark-mode-box-shadow); */
  position: relative;
  padding: 16px 24px;
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  /* box-shadow: 0px 0px 38px -3px rgba(0, 0, 0, 0.12); */
  border-radius: 8px;
}
/* .dashboard_card:nth-child(1) {
  border-left: 5px solid var(--color-success);
} */
/* .dashboard_card:nth-child(2){
  border-left: 5px solid var(--color-secondary);
}
.dashboard_card:nth-child(3) {
  border-left: 5px solid var(--color-warning);
}
.dashboard_card:nth-child(4) {
  border-left: 5px solid var(--color-success400);
} */
.dashboard_card:nth-child(1),
.dashboard_card:nth-child(2) {
  margin-right: 28px;
}
.dashboard_card_header_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.card_flex {
  display: flex;
  align-items: center;
  height: 100%;
}
.dashboard_card_header {
  /* margin: 1rem 1rem 0; */
  font-size: 16px !important;
  font-weight: 500;
  line-height: normal;
  color: var(--color-textBlack);
}
.dashboard_card_small_header {
  margin-top: 1rem ;
  font-size: 16px !important;
  font-weight: 500;
  /* line-height: 22px; */
  color: var(--color-text);
}
.diff_count {
  font-size: 12px;
  /* margin-left: 1rem; */
  font-weight: 500;
  line-height: 20px;
  color: var(--color-theme-text);
}
.top_banner {
  align-items: center;
  background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgb(67, 67, 67) 100%);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  margin: 1.5rem;
}
.dashboard_card_body {
  margin-right: 16px;
  font-size: 38px !important;
  font-weight: 600;
  line-height: 28px;
  color: var(--color-text);
}

.word_cloud_container {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 28px;
  margin-top: 0;
}

.word_cloud {
  background-color: var(--color-background);
  max-height: 500px;
  /* min-height: 385px; */
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  /* border: 2px solid var(--color-grey); */
  /* box-shadow: var(--dark-mode-box-shadow);
  border-radius: 10px; */
  /* background-color: var(--color-background); */
  /* border: 1px solid var(--color-stroke); */
  /* box-shadow: 0px 0px 38px -3px rgba(0, 0, 0, 0.12); */
  border-radius: 12px;
}

.cloud--chart {
  /* flex: 1.5; */
  margin-right: 28px;
  width: 50%;
}
.cloud--table {
  flex: 2;
}

.top_tables_container {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 28px;
  margin-top: 0;
  /* background-color: var(--color-bodyBG); */
}

.top_table--first {
  display: flex;
  flex-direction: row;
  flex: 2;
  margin-right: 28px;
}
.top_table--secound {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-right: 28px;
}
.feed_card {
  border-radius: 12px;
  background-color: var(--color-background);
  min-height: 377px;
  max-height: 500px;
  overflow: auto;
  width: 100%;
  /* margin-bottom: 30px; */
}

.feed_card::-webkit-scrollbar {
  width: 5px !important;
  background: var(--color-light-grey) !important;
}
.feed_card::-webkit-scrollbar-thumb {
  background: #888 !important;
  height: 10px !important;
}
.scrollbar ::-webkit-scrollbar {
  width: 5px !important;
  background: var(--color-light-grey) !important;
}
.scrollbar ::-webkit-scrollbar-thumb {
  background: #888 !important;
  height: 10px !important;
}

.top_table {
  flex: 1;
  /* border: 2px solid var(--color-grey); */
  /* box-shadow: var(--dark-mode-box-shadow);
  border-radius: 10px; */
  max-height: 600px;
  /* min-height: 385px; */
  background-color: var(--color-background);
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  /* border: 1px solid #e5e5e5; */
  /* box-shadow: 0px 0px 38px -3px rgba(0, 0, 0, 0.12); */
  border-radius: 12px;
}

/* .top_asset {
  margin-right: 1.5rem;
} */
.dashboard_table_title_div {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 14px 20px;
}
.dashboard_table_title {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* margin: 24px 1rem;
  margin-bottom: 0; */
  color: var(--color-text);
}
.dashboard_table_feed_title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: var(--color-text);
}
.dashboard_table_heading {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-text);
}
.dashboard_table_heading-severity {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-text);
}

.dashboard_table_heading:nth-last-child(1) {
  flex: 0.2;
  width: 100px;
}
.table-ellipsis {
  display: -webkit-box;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  line-height: 21px;
  overflow: hidden;
}
.feed-title {
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  color: var(--color-text);
  display: -webkit-box;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  line-height: 21px;
  overflow: hidden;
}
.feed-title:hover {
  color: var(--color-primary);
  text-decoration: underline;
}

.dashboard_table_feed_heading:nth-last-child(1) {
  flex: 0.1;
  width: 100px;
}
.dashboard_table_header {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid var(--color-stroke);
  /* margin: 1rem; */
  /* background: #f9fbff; */
}

.exposure--heading > .dashboard_table_heading {
  flex: 1;
}
.exposure--heading > .dashboard_table_heading:nth-child(2),
.exposure--heading > .dashboard_table_heading:nth-child(3) {
  width: 100px;
  flex: 0.2;
}
.headerGreeting {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0 1rem 1.5rem;
  width: auto;
  position: relative;
}
.headerUser {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-text);
  margin-bottom: 1rem;
  width: auto;
}
.headerPage {
  font-weight: 700;
  color: var(--color-text);
  margin-bottom: 1rem;
  width: auto;
}
.dashboard_table {
  border: 1px solid var(--color-stroke);
  margin: 1rem;
  border-radius: 12px;
  min-height: 283px;
}
.dashboard_table_body {
  flex: 1;
  padding:  12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.dashboard_table_body:nth-child(even), .dashboard_feed_body:nth-child(even) {
  background: #FAFBFE;
}
.dashboard_feed_body {
  /* border-bottom: 1px solid var(--color-light-grey); */
  cursor: pointer;
  padding: 12px ;
  transition: all 0.3s ease-in-out;
  justify-content: space-between;
  /* margin-bottom: 10px; */
  width: 100%;
}
.dashboard_table_data {
  color: var(--color-text);
  flex: 1;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* display: flex; */
  align-items: center;
}
@media only screen and (max-width: 1560px) {
  .card__top{
    padding: 12px;
  }
  .dashboard_card_body{
    font-size: 28px !important;
  }
  .dashboard_card_small_header{
    font-size: 14px !important;
  }
  .dashboard_table_title, .dashboard_table_feed_title{
    font-size: 16px !important;
    line-height: 24px;
  }
  .dashboard_table_data, .feed-title, .table--date{
    font-size: 12px;
  }
  .feed_card {
    min-height: 367px;
  }
}
/* @media only screen and (max-width: 1300px) {
  .top_tables_container {
    flex-direction: column;
  }
  .top_table--first {
    flex-direction: column;
    margin-right: 0rem;
  }
  .top_asset {
    margin-right: 0rem;
  }
  .top_table, .top_table--first {
    margin-bottom: 1.5rem;
  }
} */
@media only screen and (max-width: 1150px) {
  .dashboard_card_container {
    flex-wrap: wrap;
    margin-right: 0rem;
    margin-bottom: 0rem;
  }
  .dashboard_card {
    min-width: 240px;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .feed_card {
    width: auto;
  }
  /* .word_cloud_container {
    flex-direction: column;
  } */
  /* .cloud--chart {
    margin-left: 0rem;
    margin-bottom: 1.5rem;
    margin-right: 0rem;
  }
  .top_table--secound {
    margin-left: 0rem;
    margin-top: 1.5rem;
    margin-right: 0rem;
  } */
}
.table--data {
  flex: 1;
}
.top_asset_types .table--data:hover {
  text-decoration: underline;
  color: var(--color-primary);
  cursor: pointer;
}
.exposure .table--data:hover {
  text-decoration: underline;
  color: var(--color-primary);
  cursor: pointer;
}

.table--count {
  width: 100px;
  flex: 0.2;
}
.table--date {
  font-size: 13px;
  color: var(--color-grey);
  font-weight: 400;
  margin-top: 4px;
}
.table--text {
  font-size: 14px;
  color: var(--color-text);
  font-weight: 400;
  margin-right: 10px;
}

.table--count.status__tags {
  padding: 0;
}

.show_diff {
  display: flex;
  flex-direction: row;
  /* position: absolute;
  bottom: 20px;
  right: 20px; */
  color: var(--color-primary);
  font-weight: 500;
  font-size: 14px;
  align-items: center;
}

.gauge_chart_card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gauge_chart_card > .dashboard_card_header {
  margin-right: auto;
}

.gauge_chart_title {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}

.gauge_chart_value {
  font-weight: 600;
}
.gauge_chart {
  width: 130px;
  /* position: absolute;
  bottom: 20px;
  right: 20px; */
}
.main_screen {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 100px;
  /* width: 100%; */
  transition: margin 0.3s;
}

.scan_info {
  flex: 1;
  text-align: left;
}

.scan_date {
  text-align: right;
}

.scan_id {
  color: var(--color-text);
  display: flex;
}

.scan_id span,
.scan_date span {
  font-weight: 500;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--color-bodyBG) inset !important;
  -webkit-box-shadow: 0 0 0 30px var(--color-bodyBG) inset !important;
  -webkit-text-fill-color: var(--color-text) !important;
}
.scan_info_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.status__tags {
  padding: 2px 15px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 900;
  justify-content: flex-start;
  width: auto;
  color: var(--color-white);
}
.status {
  padding: 2px 15px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 900;
  justify-content: flex-start;
  width: auto;
  color: var(--color-white);
}
.status__tags::before {
  content: '';
  display: none;
  width: 9px;
  height: 9px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  margin-right: 5px;
}
.running {
  color: var(--color-running);
  /* border: 1px solid var(--color-running); */
  /* width: 150px; */
}

.running:before {
  background-color: var(--color-running);
  display: inline-block;

  display: inline-block;
}

.pending {
  color: var(--color-pending);
  /* border: 1px solid var(--color-pending); */
  /* width: 150px; */
}

.pending:before {
  background-color: var(--color-pending);
  display: inline-block;
}

.finished {
  color: var(--color-finished);
  /* border: 1px solid var(--color-finished); */
  /* width: 150px; */
}

.finished::before {
  background-color: var(--color-finished);
  display: inline-block;
}

.scheduled {
  color: var(--color-scheduled);
  /* border: 1px solid var(--color-scheduled); */
}

.scheduled:before {
  background-color: var(--color-scheduled);
  display: inline-block;
}

.validating {
  color: var(--color-validating);
  /* border: 1px solid var(--color-validating); */
  /* width: 150px; */
}

.validating:before {
  background-color: var(--color-validating);
  display: inline-block;
}

.monitoring {
  color: var(--color-running);
  /* border: 1px solid var(--color-running); */
  /* width: 150px; */
}

.monitoring:before {
  background-color: var(--color-running);
  display: inline-block;
}

.user_roles {
  padding: 5px;
  border-radius: 50px;
  letter-spacing: 1px;
  color: var(--color-white);
  font-weight: 700;
  width: auto;
}

.pending-role {
  background-color: var(--color-pending);
}
.admin-role {
  background-color: var(--color-admin);
}
.invited-role {
  background-color: var(--color-disabled);
}

.manager-role {
  background-color: var(--color-manager);
}

.reporter-role {
  background-color: var(--color-reporter);
}

.deleted-role {
  background-color: var(--color-deleted);
}

.rh-admin-role {
  background-color: var(--color-admin);
}

.rh-manager-role {
  background-color: var(--color-manager);
}

.org_pending-role {
  background-color: var(--color-pending);
}

.verified-role {
  background-color: var(--color-light-info);
  color: var(--color-info);
}

.disabled-role {
  background-color: var(--color-disabled);
}

.self-role {
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
  padding: 2px 7px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 700;
}

.vendor-role {
  background-color: var(--color-high);
}

.OPEN {
  padding: 1px 5px;
  border-radius: 4px;
  font-size: 14px;
  width: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--color-grey);
}
.CLOSED {
  padding: 1px 5px;
  border-radius: 4px;
  font-size: 14px;
  width: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--color-grey);
}
.PROGRESS {
  padding: 1px 5px;
  border-radius: 4px;
  font-size: 14px;
  width: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--color-grey);
}
.FIX {
  padding: 1px 5px;
  border-radius: 4px;
  font-size: 14px;
  width: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--color-grey);
}
.Assignee {
  background: var(--color-background) !important;
  color: var(--color-text) !important;
  padding: 5px 5px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  cursor: pointer;
  border: 2px solid var(--color-grey);
}
.Priority {
  background: var(--color-background) !important;
  color: var(--color-text) !important;
  padding: 5px 5px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 117px;
  cursor: pointer;
  border: 2px solid var(--color-grey);
}
.issue-type-select {
  background: var(--color-background) !important;
  color: var(--color-text) !important;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 94%;
  cursor: pointer;
  border: 2px solid var(--color-grey);
}
.statusBtn {
  background: var(--color-background) !important;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
  cursor: pointer;
  border: 2px solid var(--color-grey);
}
.P0 {
  background: var(--color-primary) !important;
  color: var(--color-white) !important;
  padding: 1px 5px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  border: 2px solid var(--color-grey);
}
.P1 {
  background: var(--color-high) !important;
  color: var(--color-white) !important;
  padding: 1px 5px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  border: 2px solid var(--color-grey);
}
.P2 {
  background: var(--color-moderate) !important;
  color: var(--color-white) !important;
  padding: 1px 5px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  border: 2px solid var(--color-grey);
}
.P3 {
  background: var(--color-low) !important;
  color: var(--color-white) !important;
  padding: 1px 5px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  border: 2px solid var(--color-grey);
}
.P4 {
  background: var(--color-dark-grey) !important;
  color: var(--color-white) !important;
  padding: 1px 5px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  border: 1px solid var(--color-grey);
}
.P0-menu {
  color: var(--color-high) !important;
  cursor: pointer;
}
.P1-menu {
  color: var(--color-high) !important;
  cursor: pointer;
}
.P2-menu {
  color: var(--color-moderate) !important;
  cursor: pointer;
}
.P3-menu {
  color: var(--color-low) !important;
  cursor: pointer;
}
.P4-menu {
  color: var(--color-dark-grey) !important;
  cursor: pointer;
}

.status-menu {
  color: var(--color-text) !important;
  cursor: pointer;
}
.OPEN-menu {
  color: var(--color-running) !important;
  cursor: pointer;
}
.CLOSED-menu {
  color: var(--color-verified) !important;
  cursor: pointer;
}
.PROGRESS-menu {
  color: var(--color-moderate) !important;
  cursor: pointer;
}
.FIX-menu {
  color: var(--color-aborted) !important;
  cursor: pointer;
}

.high {
  color: var(--color-high);
}

.high.status__tags::before {
  background-color: var(--color-high);
  display: inline-block;
}

.critical {
  color: var(--color-critical);
}

.critical.status__tags::before {
  background-color: var(--color-critical);
  display: inline-block;
}
.aborted {
  background-color: var(--color-aborted);
  color: var(--color-white);
  width: 150px;
}

.disabled {
  background-color: var(--color-disabled);
  color: var(--color-white);
}

.moderate {
  color: var(--color-moderate);
}
.moderate.status__tags::before {
  background-color: var(--color-moderate);
  display: inline-block;
}
.low.credits {
  color: var(--color-white);
  width: 150px;
  background-color: var(--color-error);
}

.low {
  color: var(--color-low);
}
.low.status__tags::before {
  background-color: var(--color-low);
  display: inline-block;
}
.abort {
  background-color: var(--color-aborted);
  color: var(--color-white);
}

.errored {
  border: 1px solid var(--color-error);
  color: var(--color-error);
  width: 150px;
}

.deleted {
  border: 1px solid var(--color-deleted);
  color: var(--color-deleted);
  width: 150px;
}

.na {
  color: var(--color-dark-grey);
}
.info {
  color: var(--color-dark-grey);
}
.informational {
  color: var(--color-dark-grey);
}

.na.status__tags::before {
  background-color: var(--color-dark-grey);
  display: inline-block;
}
.info.status__tags::before {
  background-color: var(--color-dark-grey);
  display: inline-block;
}
.na-groupBy {
  background-color: var(--color-background);
  color: var(--color-text) !important;
}

a {
  text-decoration: none;
  color: var(--color-text);
}

.MuiPaper-rounded {
  border-radius: 5px;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiChip-clickableColorPrimary {
  background-color: var(--color-info);
}

.MuiCheckbox-colorSecondary {
  color: var(--color-light-text) !important;
}
.MuiCheckbox-colorSecondary.Mui-disabled {
  color: var(--color-disabled) !important;
}
.MuiFormControlLabel-label.Mui-disabled {
  color: var(--color-disabled) !important;
}

thead .MuiCheckbox-colorSecondary {
  color: var(--color-white) !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--color-success) !important;
}
thead .MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--color-white) !important;
}

/* [class^='makeStyles-highlight-'],
[class*=' makeStyles-highlight-'] {
  background-color: var(--color-highlightedBG) !important;
} */

.MuiTableCell-root {
  font-size: 13.5px !important;
}

.MuiTypography-body1 {
  font-size: 14px !important;
  font-weight: 500 !important;
}

#tableTitle {
  font-size: 14px;
}

/* .MuiSvgIcon-root {
  font-size: 20px !important;
} */

.MuiBadge-colorPrimary {
  color: var(--color-bodyBG) !important;
  background-color: var(--color-primary) !important;
  font-weight: 700 !important;
}
.MuiBadge-dot {
  color: var(--color-bodyBG) !important;
  background-color: var(--color-danger300) !important;
  font-weight: 700 !important;
}
.tablePagination {
  display: flex;
  align-items: center;
  flex: 1.5;
  height: 50px;
  justify-content: flex-end;
  color: var(--color-light-text);
}
.pagination {
  margin: 0 1rem;
  display: flex;
  align-items: center;
}
.pagination li.page-item,
.pagination li.break,
.pagination li.next,
.pagination li.previous,
.pagination li.break-label {
  list-style: none;
}

.pagination li.next a,
.pagination li.previous a {
  outline: none;
}
.pagination li.previous.disabled,
.pagination li.next.disabled,
.pagination li.previous.disabled a,
.pagination li.next.disabled a {
  background-color: transparent;
  outline: none;
}

.pagination li.page-item {
  cursor: pointer;
}

.pagination li.page-item.active a.page-link {
  color: var(--color-white) !important;
  background: var(--color-primary) !important;
  border-radius: 5px;
  border-color: var(--color-primary) !important;
  cursor: pointer;
  outline: none;
}

.pagination li.page-item a:active {
  outline: none;
}

a.page-link:active {
  outline: none;
}

.pagination li.break-label,
.pagination li.break-label a.active-break-label {
  outline: none;
  width: 1rem;
  font-weight: 900;
  font-size: 1rem;
  padding: 0.55rem 1rem;
  cursor: pointer;
}
.pagination a.page-link,
.pagination li.break {
  padding: 0.55rem 1rem;
  min-width: 3.5rem;
  text-align: center;
  box-shadow: none !important;
  border-color: var(--color-muted) !important;
  color: var(--color-muted);
  font-weight: 900;
  font-size: 1rem;
}

.pagination a.page-link:hover {
  background-color: var(--color-light-grey);
  border-radius: 5px;
}
.dateFilter {
  display: flex;
  align-items: center;
  width: 450px;
  padding: 10px 20px;
}
.notificationDateFilter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 0;
}
.dateFilter .react-datepicker__input-container input {
  padding: 10px 20px;
  width: 120px;
}
.notificationDateFilter .react-datepicker__input-container input {
  padding: 10px 20px;
  width: 100px;
  margin: 0 4px;
}
.dateFilter .react-datepicker__close-icon::after {
  background-color: var(--color-secondary);
  color: var(--color-white);
}
.notificationDateFilter .react-datepicker__close-icon::after {
  background-color: var(--color-secondary);
  color: var(--color-white);
}
.notificationDateFilter .react-datepicker__close-icon {
  padding: 0 10px 0 0;
}
.react-datepicker-wrapper {
  display: flex !important;
  color: var(--color-white) !important;
  flex: 1;
  margin: 5px 0;
}

.react-datepicker-popper {
  background-color: transparent;
}
.react-datepicker__input-container {
  display: flex !important;
  flex: 1;
}

.react-datepicker__input-container input {
  padding: 1rem;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  border: 1px solid var(--color-light-text);
  display: flex;
  flex: 1;
  background-color: var(--color-bodyBG);
}

.react-datepicker__month-container {
  color: var(--color-text);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: var(--color-text) !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: var(--color-text) !important;
}

.react-datepicker__day-name {
  color: var(--color-text) !important;
}

.react-datepicker__triangle {
  border-bottom-color: var(--color-primary) !important;
}
.dateFilter .react-datepicker__triangle {
  border-bottom-color: #14274e !important;
}

.dateFilter .react-datepicker__day--keyboard-selected,
.dateFilter
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.dateFilter .react-datepicker__day--selected {
  background-color: #14274e !important;
  color: #fff !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker__day .react-datepicker__day--selected,
.react-datepicker__day--selected {
  background-color: var(--color-white) !important;
  color: var(--color-secondary500) !important;
  border: 1px solid var(--color-secondary500) !important;
  font-weight: 600;
}

.react-datepicker__header {
  height: 45px;
  background-color: var(--color-white) !important;
  color: var(--color-text) !important;
  border: none !important;
}
.dateFilter .react-datepicker__header {
  height: 45px;
  background-color: #14274e !important;
  color: var(--color-white) !important;
}

.react-datepicker__header--time {
  height: 37px;
  color: var(--color-white) !important;
}

.react-datepicker__day {
  /* margin: 10px !important; */
}
.react-datepicker__time-name,
.react-datepicker__input-container input {
  color: var(--color-text) !important;
}
.react-datepicker__day-name {
  color: var(--color-text) !important;
  /* margin: 10px !important; */
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  color: var(--color-black) !important;
}

.react-datepicker__time,
.react-datepicker {
  /* border-color: var(--color-light-text) !important; */
  background-color: var(--color-background) !important;
  color: var(--color-text) !important;
  border: none !important;
  font-size: 16px !important;
}
.MuiOutlinedInput-multiline {
  background-color: var(--color-bodyBG) !important;
}

.MuiMenu-list {
  background-color: var(--color-background) !important;
}
.MuiList-root li:hover {
  background-color: var(--color-highlightedBG) !important;
  color: var(--color-primary) !important;
}
.MuiList-root li:hover a {
  color: var(--color-primary) !important;
}

.MuiFormHelperText-root,
.MuiRadio-root,
.MuiInputBase-root,
.MuiMenu-list,
.MuiDialog-paper,
.MuiDialogContentText-root {
  color: var(--color-light-text) !important;
}

.MuiInputLabel-root {
  color: var(--color-light-text) !important;
}

.MuiFormHelperText-root.Mui-error {
  color: var(--color-error) !important;
}

.SrListSort .MuiPaper-root.MuiPopover-paper.MuiPaper-rounded {
  box-shadow: var(--light-mode-box-shadow);
  width: 200px;
  margin-top: 20px;
}
.IssurSort .MuiPaper-root.MuiPopover-paper.MuiPaper-rounded {
  box-shadow: var(--light-mode-box-shadow);
  width: 200px;
}

.MuiPaper-root.MuiPopover-paper.MuiPaper-rounded {
  /* margin-top: 40px; */
  box-shadow: var(--light-mode-box-shadow);
}
.notification-popup .MuiPaper-root.MuiPopover-paper.MuiPaper-rounded {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14);
  border-radius: 4px !important;
}

.MuiDialogActions-root {
  background: var(--color-white) !important;
  border-top: 1px solid var(--color-stroke);
}

.dashboard_feed_body .MuiPaper-root {
  background-color: transparent !important;
}
.dashboard_feed_body .MuiIconButton-root {
  color: var(--color-text) !important;
}
.MuiDialog-paper {
  background-color: var(--color-background) !important;
  border-radius: 8px !important;
}

table {
  /* border-collapse: separate !important; */
  /* border-spacing: 0 10px !important; */
}
table.MuiTable-root {
  height: auto;
  border-collapse: separate !important;
  border-spacing: 0 !important;
}

.MuiTableHead-root {
  background-color: var(--color-primary) !important;
}

thead th {
  /* color: var(--color-white) !important; */
  font-weight: 900 ;
  text-transform: capitalize;
}

/* thead tr {
  box-shadow: var(--light-mode-box-shadow) !important;
  background-color: var(--color-primary) !important;
  border-radius: 8px;
} */

/* tbody tr {
  background-color: var(--color-background) !important;
  box-shadow: var(--light-mode-box-shadow) !important;
  border-radius: 8px;
} */

/* tbody tr:hover {
  background-color: var(--color-highlightedBG) !important;
} */

/* td:nth-last-child(1),
th:nth-last-child(1) {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
} */
.MuiTableCell-root {
  border-bottom: none !important;
  padding: 16px 0;
}
/* td:nth-child(1),
th:nth-child(1) {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
} */

.MuiTableRow-root.Mui-selected {
  background-color: var(--color-highlightedBG) !important;
}

.notification {
  padding: 8px 16px;
  /* margin: 0.5rem 1rem; */
  border-bottom: 1px solid var(--color-grey);
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  line-height: 1.7;
  cursor: pointer;
}
.avtar-bg {
  background: var(--color-primary);
  border-radius: 50px;
  width: 25px;
  color: var(--color-white);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.exportReport {
  padding: 12px;
  margin: 0;
  border: 2px solid transparent;
  border-bottom: 1px solid var(--color-light-grey);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  line-height: 1.7;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.notification:hover {
  opacity: 0.9;
}

.unread_notification {
  background-color: var(--color-highlightedBG);
  font-weight: 700 !important;
  color: var(--color-primary);
}
.unread_notification:hover {
  background-color: var(--color-light-grey);
}

.read_notification {
  background-color: var(--color-background);
  color: var(--color-text);
}
.read_notification:hover {
  background-color: var(--color-light-grey);
}

.MuiTab-textColorPrimary {
  color: var(--color-primary) !important;
  opacity: 0.7 !important;
  font-weight: 600 !important;
  background-color: var(--color-bodyBG) !important;
}
.MuiTab-textColorPrimary.Mui-selected {
  color: var(--color-primary) !important;
  opacity: 1 !important;
  font-weight: 600 !important;
  background-color: var(--color-bodyBG) !important;
}

.MuiTabs-indicator {
  background-color: var(--color-primary) !important;
}

/* .MuiTab-textColorPrimary {
  border-bottom: 2px solid var(--color-primary) !important;
} */

.MuiTab-root {
  text-transform: none !important;
  min-width: auto !important;
  padding-left: 5 !important;
  padding-right: 5 !important;
  margin-right: 36px;
  font-size: 18px !important;
  line-height: 20px !important;
  max-width: none !important;
}

.ip_address {
  font-size: 12px;
  background-color: var(--color-grey);
  color: var(--color-text);
  padding: 5px 10px;
  font-weight: 700;
}

label.file {
  flex: 1;
}

label.file input {
  font: 1rem;
  padding: 1rem;
  border: 2px solid var(--color-grey);
  border-radius: 5px;
  flex: 1;
  width: 95%;
  margin: 1rem 0;
}

.rh_settings_page {
  margin: 1rem;
  background-color: var(--color-background);
  padding: 2rem;
  border-radius: 5px;
}

.rh_settings_page_heading {
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: var(--color-text);
}

/* .MuiSwitch-colorSecondary.Mui-checked {
  color: var(--color-primary) !important;
} */

.MuiListItemText-primary {
  font-weight: 700 !important;
  font-size: 15px !important;
}
.MuiListItemIcon-root {
  margin-right: 10px;
  min-width: unset !important;
}
.active .MuiListItemIcon-root {
  color: var(--color-white) !important;
}

.MuiListItemText-root {
  width: auto !important;
}

.MuiListItem-button:hover {
  background: transparent !important;
}

.list-menu .MuiListItem-button:hover {
  background-color: transparent !important;
}
/* 
.MuiListItem-button:hover .MuiListItemText-root {
  color: var(--color-light-text);
} */

.MuiListItem-button:hover .active .MuiListItemText-root {
  color: var(--color-white);
}

.issue-conditions .MuiChip-label {
  text-align: left !important;
  color: var(--color-text) !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: var(--color-primary) !important;
}

.filter_bar_container {
  transition: all 0.3s ease-out;
  background-color: transparent;
  margin-top: 0rem;
  margin-bottom: 2rem;
  padding: 0.5rem 0rem;
  border-radius: 10px;
  height: auto;
  display: flex;
  align-items: center;
  position: relative;
  /* box-shadow: var(--light-mode-box-shadow); */
}

.MuiListItem-button:hover .MuiListItemText-root .MuiListItemText-primary span {
  color: var(--color-primary);
}

.MuiAutocomplete-inputRoot .MuiIconButton-root {
  color: var(--color-text) !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active,
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
  .MuiTableSortLabel-icon {
  color: var(--color-white) !important;
}
.horizontalLine {
  border-bottom: 0.25px solid var(--color-light-grey);
  width: auto;
  margin: 0 16px;
}

.Toastify__toast-container {
  width: 500px !important;
  border-radius: 10px;
}

.Toastify__toast-container--top-center {
  margin-left: -250px !important;
}

.Toastify__toast--success,
.Toastify__toast--error {
  border-radius: 10px !important;
}

.logo {
  padding: 10px 30px;
  max-width: 150px;
}

.logo img {
  width: 100%;
  height: auto;
  border-radius: 0;
}

.wallet__balance {
  text-align: right;
  line-height: 1.5;
  text-align: left;
}

.credits_title {
  color: var(--color-text);
  font-size: 16px !important;
  font-weight: 700;
}

.credits_value {
  color: var(--color-primary);
  font-weight: 700;
  font-size: 20px !important;
}

.frozen_credits {
  color: var(--color-primary);
}

.MuiTableCell-body {
  color: var(--color-text) !important;
}

.MuiTableCell-root {
  border-color: var(--color-grey) !important;
}

.apexcharts-xaxis-label {
  fill: var(--color-text);
}

.MuiInputBase-input.MuiOutlinedInput-input {
  /* background-color: vasr(--color-bodyBG) !important; */
  color: var(--color-light-text) !important;
}
.assets_page .MuiOutlinedInput-input {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.MuiInputLabel-outlined {
  color: var(--color-light-text) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--color-stroke) !important;
  border-radius: 4px !important;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-error) !important;
}

.MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiTableSortLabel-root:hover {
  color: var(--color-white) !important;
}

.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon {
  display: flex !important;
}

.orgCredits {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--color-background);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  /* width: 100%; */
  border: 2px solid var(--color-grey);
}

.scanDetailsNumberContainer {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.scanDetailNumber {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 32px;
}

.credits {
  width: 30%;
  display: flex;
  border-right: 0.25px solid var(--color-grey);
  justify-content: center;
}
.MuiPaper-rounded {
  border-radius: 8px !important;
}

.InovuaReactDataGrid--show-hover-rows {
  background-color: var(--color-background) !important;
}

.InovuaReactDataGrid__row-hover-target:hover {
  background: var(--color-lighter-grey) !important;
  color: var(--color-text) !important;
}
.InovuaReactDataGrid__row--selected {
  background: var(--color-lighter-grey) !important;
  color: var(--color-text) !important;
  font-weight: 500 !important;
}

.inovua-react-toolkit-checkbox--checked {
  fill: var(--color-primary) !important;
}
.inovua-react-toolkit-checkbox.inovua-react-toolkit-checkbox--theme-default-light.inovua-react-toolkit-checkbox--indeterminate
  .inovua-react-toolkit-checkbox__icon-wrapper
  svg {
  background: var(--color-primary) !important;
  fill: var(--color-white) !important;
}
.inovua-react-toolkit-checkbox.inovua-react-toolkit-checkbox--theme-default-light.inovua-react-toolkit-checkbox--checked
  .inovua-react-toolkit-checkbox__icon-wrapper {
  fill: var(--color-primary) !important;
}

.InovuaReactDataGrid__header {
  /* background: var(--color-primary) !important;
  color: var(--color-white) !important; */
  /* border: 2px solid var(--color-grey) !important; */
  color: var(--color-background) !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__header {
  background: var(--color-light-grey) !important;
  color: var(--color-primary) !important;
}

.inovua-react-toolkit-text-input.inovua-react-toolkit-text-input--theme-default-light,
.inovua-react-toolkit-combo-box.inovua-react-toolkit-combo-box--theme-default-light {
  border: 1px solid var(--color-primary) !important;
  background-color: var(--color-background) !important;
  border-radius: 2px !important;
}

.InovuaReactDataGrid--theme-default-light.InovuaReactDataGrid--filterable
  .InovuaReactDataGrid__column-resizer {
  height: 22px !important;
  /* width: 2px !important; */
  right: 0.5px !important;
  top: 13% !important;
  background: var(--color-muted) !important;
}
.inovua-react-toolkit-combo-box.inovua-react-toolkit-combo-box--theme-default-light
  .inovua-react-toolkit-combo-box__value__tag,
.inovua-react-toolkit-text-input.inovua-react-toolkit-text-input--theme-default-light
  input.inovua-react-toolkit-text-input__input {
  background-color: transparent !important;
  color: var(--color-primary) !important;
  font-weight: 700 !important;
}

.InovuaReactDataGrid__column-header--first {
  border-top-left-radius: 10px !important;
}

.InovuaReactDataGrid__column-header--last {
  border-top-right-radius: 10px !important;
}

.InovuaReactDataGrid__column-header__menu-tool
  .InovuaReactDataGrid__sort-icon--asc,
.InovuaReactDataGrid__column-header__menu-tool
  .InovuaReactDataGrid__sort-icon--desc {
  fill: var(--color-text) !important;
  background-color: var(--color-background) !important;
  width: 15px !important;
  height: 15px !important;
}
/* .InovuaReactDataGrid__column-header__content.InovuaReactDataGrid__box--ellipsis
  > .inovua-react-toolkit-checkbox.inovua-react-toolkit-checkbox--theme-default-light.inovua-react-toolkit-checkbox--unchecked
  .inovua-react-toolkit-checkbox__icon-wrapper {
  stroke: var(--color-white) !important;
} */
/* 
.InovuaReactDataGrid__column-header__content.InovuaReactDataGrid__box--ellipsis
  > .inovua-react-toolkit-checkbox.inovua-react-toolkit-checkbox--theme-default-light.inovua-react-toolkit-checkbox--checked
  .inovua-react-toolkit-checkbox__icon-wrapper {
  stroke: var(--color-primary) !important;
  fill: var(--color-white) !important;
} */

.inovua-react-toolkit-load-mask.inovua-react-toolkit-load-mask--theme-default-light
  .inovua-react-toolkit-load-mask__loader--svg
  .inovua-react-toolkit-load-mask__loader-spinner {
  background-image: conic-gradient(
    from 90deg,
    var(--color-highlightedBG),
    var(--color-primary) 0.99turn,
    var(--color-highlightedBG)
  ) !important;
}
.inovua-react-toolkit-load-mask.inovua-react-toolkit-load-mask--theme-default-light
  .inovua-react-toolkit-load-mask__loader--svg:before {
  background: var(--color-background) !important;
}
.inovua-react-toolkit-load-mask.inovua-react-toolkit-load-mask--theme-default-light
  .inovua-react-toolkit-load-mask__loader--svg:after {
  background: var(--color-background) !important;
}
.MuiAutocomplete-popper div {
  background-color: var(--color-bodyBG) !important;
  color: var(--color-text) !important;
}
.inovua-react-toolkit-load-mask.inovua-react-toolkit-load-mask--theme-default-light
  .inovua-react-toolkit-load-mask__background-layer {
  background: rgba(0, 0, 0, 0) !important;
  border-radius: 10px !important;
}

.InovuaReactDataGrid__loading-text {
  color: var(--color-text) !important;
  font-weight: 700 !important;
}
.inovua-react-toolkit-combo-box__list__item {
  background: var(--color-background) !important;
  padding-right: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}
.inovua-react-toolkit-combo-box__list--theme-default-light {
  background: var(--color-background) !important;
}
.inovua-react-toolkit-combo-box__list--theme-default-light
  ::-webkit-scrollbar-track {
  background: var(--color-background) !important;
}
.inovua-react-toolkit-combo-box__list--theme-default-light ::-webkit-scrollbar {
  background: var(--color-background) !important;
}
.inovua-react-toolkit-combo-box__list--theme-default-light
  ::-webkit-scrollbar-corner {
  background: var(--color-background) !important;
}

.inovua-react-scroll-container__view.inovua-react-scroll-container__view--zero-width-scrollbar {
  margin-bottom: 0 !important;
}

.inovua-react-toolkit-combo-box__list__item:hover {
  background: var(--color-highlightedBG) !important;
}
.inovua-react-toolkit-combo-box__list__item--selected {
  background: var(--color-highlightedBG) !important;
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--even,
.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--no-zebra {
  background: var(--color-background) !important;
}

.InovuaReactDataGrid--theme-default-light
  .InovuaReactDataGrid__cell--show-border-bottom,
.InovuaReactDataGrid--theme-default-light
  .InovuaReactDataGrid__footer-row-cell--show-border-bottom,
.InovuaReactDataGrid--theme-default-light
  .InovuaReactDataGrid__locked-row-cell--show-border-bottom {
  border-bottom: 1px solid vaR(--color-light-grey) !important;
}

.InovuaReactDataGrid--theme-default-light
  .InovuaReactDataGrid__column-header__filter-wrapper {
  background: var(--color-background) !important;
}

.InovuaReactDataGrid--theme-default-light
  .InovuaReactDataGrid__column-header__filter-wrapper {
  border: none !important;
}
.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__header,
.InovuaReactDataGrid--theme-default-light
  .InovuaReactDataGrid__header-wrapper__fill {
  border-bottom: 2px solid var(--color-light-grey) !important;
}

.InovuaReactDataGrid--theme-default-light
  .InovuaReactDataGrid__column-resize-handle,
.InovuaReactDataGrid--theme-default-light
  .InovuaReactDataGrid__header-group-resize-handle {
  background: var(--color-primary) !important;
}
.InovuaReactDataGrid--theme-default-light
  .InovuaReactDataGrid__column-header--show-border-left,
.InovuaReactDataGrid--theme-default-light
  .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left,
.InovuaReactDataGrid--theme-default-light
  .InovuaReactDataGrid__header-group__title--show-border-left {
  border-left: none !important;
}

.InovuaReactDataGrid--theme-default-light
  .inovua-react-pagination-toolbar.inovua-react-pagination-toolbar--theme-default-light {
  border-radius: 0 0 10px 10px !important;
  background: var(--color-background) !important;
  border-top: 2px solid var(--color-light-grey) !important;
  color: var(--color-text) !important;
}

.InovuaReactDataGrid--theme-default-light
  .InovuaReactDataGrid__header-wrapper__fill__filters {
  background: var(--color-background) !important;
}
.inovua-react-toolkit-menu {
  background: var(--color-background) !important;
}
.orgListTable .InovuaReactDataGrid__header-wrapper {
  padding: 10px;
  display: block !important;
}
.rh_security_table .InovuaReactDataGrid__header-wrapper {
  padding: 0 !important;
  display: block !important;
}
.orgListTable
  .InovuaReactDataGrid--theme-default-light.InovuaReactDataGrid--filterable
  .InovuaReactDataGrid__column-resizer {
  /* position: unset; */
  /* height: auto !important; */
}
.orgListTable .InovuaReactDataGrid__header {
  background: #e6eeff !important;
  min-width: auto !important;
  color: #4d4d4d !important;
}
.orgListTable
  .inovua-react-toolkit-text-input.inovua-react-toolkit-text-input--theme-default-light {
  border: 1px solid var(--color-light-grey) !important;
}

.InovuaReactDataGrid--theme-default-light
  .InovuaReactDataGrid__column-header__filter-wrapper--disabled,
.InovuaReactDataGrid--theme-default-light
  .InovuaReactDataGrid__column-header__filter-wrapper--read-only {
  background-color: var(--color-grey) !important;
}
.inovua-react-toolkit-menu__row.inovua-react-toolkit-menu__row--over {
  background: var(--color-highlightedBG) !important;
}
.inovua-react-toolkit-menu {
  background: var(--color-background) !important;
}
.inovua-react-toolkit-menu__cell.inovua-react-toolkit-menu__cell--ltr {
  color: var(--color-text) !important;
}
.inovua-react-toolkit-menu__row.inovua-react-toolkit-menu__row--disabled
  .inovua-react-toolkit-menu__cell.inovua-react-toolkit-menu__cell--ltr {
  color: var(--color-disabled) !important;
}
.InovuaReactDataGrid__cell__content {
  font-size: 15px;
}

.billing_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.billing_page_header {
  color: var(--color-text);
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 1.5rem;
}
.billing_tagline {
  flex: 1;
  margin-top: 1rem;
}
.subscription_plans_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.subscription_plans_card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 25%;
  align-items: center;
  border: 1px solid #dbdbdb;
  border-radius: 4;
}
.subscription_plan {
  /* height: 500px; */
  width: 380px;
  background: var(--color-background);
  /* border: 2px solid var(--color-grey); */
  filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.09));
  margin: 2rem 1rem;
  border-radius: 4px;
  width: 426.67px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 1px;
  transition: 0.3s all ease-in-out;
  color: var(--color-text);
}

.plan_header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 24px;
  width: 87%;

  background: #ffffff;
  /* Text/Border Stroke/400 */

  border-bottom: 1px solid #dbdbdb;
}

.plan_icon {
  border: 2px solid var(--color-grey);
  border-radius: 10px;
}

.plan_title {
  font-weight: 500;

  line-height: 42px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0025em;
  background: linear-gradient(92.65deg, #ae2b22 0%, #da4b42 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  justify-content: center;
  width: 100%;
}

.plan_name {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 32px;
}

.plan_price {
  font-weight: 500;
  font-size: 32px;
  width: 100%;
  text-align: center;
}

.plan_info {
  margin: 2rem;
}

.plan_info_heading {
  font-size: 14px;
  font-weight: 700;
  margin: 2rem 0;
}

.plan_info_features {
  display: flex;
  flex-direction: column;
  width: 87%;
  margin: 40px 24px;
}

.plan_info_feature {
  flex: 1;
  display: flex;
  align-items: center;
}

.plan_action {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}

.create_subscription:hover {
  box-shadow: var(--image-box-shadow);
  transform: scale(1.05);
}

.billing_page_footer {
  color: var(--color-light-text);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-weight: 500;
  text-align: center;
}

.contact_us {
  color: var(--color-primary);
  cursor: pointer;
  margin-left: 1rem;
  display: flex;
  align-items: center;
}
.contact_us:hover {
  text-decoration: underline;
}

.selected_payment_plan {
  display: flex;
  flex-direction: row;
  background: var(--color-highlightedBG);
  box-shadow: var(--light-mode-box-shadow);
  margin: 1rem;
  width: 792px;
  border-radius: 10px;
  color: var(--color-text);
  align-items: center;
  justify-content: space-between;
}

.selected--plan_icon {
  border: 1px solid var(--color-text);
}
.change--plan_action {
  margin-top: 0;
  margin-right: 2rem;
  margin-bottom: 0;
}

.payment_details {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  justify-content: flex-start;
  width: 792px;
}

.payment_details_header {
  display: flex;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  margin-top: 1rem;
}

.subscription_page {
  display: flex;
  flex-direction: column;
}

.subscription_info_container {
  display: flex;
  flex-direction: row;
}

.selected--plan {
  margin: 2rem;
  margin-left: 1.5rem;
  margin-top: 0;
  padding: 2rem;
  border-radius: 10px;
  flex: 0.4;
}

.subscription_payment_info {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.subscription_payment_method {
  margin: 2rem;
  margin-top: 0;
  margin-left: 0;
  /* border: 2px solid var(--color-grey); */
  border-radius: 10px;
  box-shadow: var(--dark-mode-box-shadow);
  padding: 2rem;
  flex: 1;
  background: var(--color-background);
}
.subscription_card_header {
  font-weight: 700;
  font-size: 16px;
  color: var(--color-primary);
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.payment_info_details {
  flex: 1;
  display: flex;
  flex-direction: row;
  background-color: var(--color-bodyBG);
  border-radius: 10px;
  padding: 2rem;
  padding-bottom: 1rem;
}

.card_icon {
  background-color: var(--color-white);
  border-radius: 100px;
  width: 50px;
  height: 50px;
  box-shadow: var(--dark-mode-box-shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}

.card_details {
  display: flex;
  flex-direction: column;
}
.card_info {
  color: var(--color-text);
  margin-bottom: 1rem;
}
.card--type {
  font-size: 13px;
}

.card--number {
  font-size: 24px;
  letter-spacing: 5px;
  border: 2px solid var(--color-grey);
  padding: 10px 20px;
  border-radius: 5px;
}

.card--owner {
  font-size: 24px;
  letter-spacing: 3px;
}

.card_details_container {
  /* border: 2px solid var(--color-grey); */
  padding: 2rem;
  border-radius: 10px;
  box-shadow: var(--dark-mode-box-shadow);
}

.selected--plan-header {
  flex-direction: column;
}

.selected--plan-header .plan_icon {
  margin-bottom: 2rem;
}
.selected_plan_info {
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.plan_amount {
  font-size: 30px;
  font-weight: 500;
}

.currency_symbol {
  margin-bottom: 10px;
  margin-right: 5px;
  font-size: 20px;
  text-transform: uppercase;
}
.selected--plan--title {
  margin-left: 0;
  text-align: center;
}

.selected--plan--title .plan_name {
  margin-bottom: 1rem;
}

.subscription_recent_payments {
  /* border: 2px solid var(--color-grey); */
  border-radius: 10px;
  box-shadow: var(--dark-mode-box-shadow);
  background: var(--color-background);
  margin: 2rem;
  margin-top: 0;
  margin-left: 0;
  padding: 2rem;
  flex: 1;
}

.invoices {
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-items: center;
}

.invoice_info {
  flex: 1;
  font-weight: 500;
  color: var(--color-text);
  font-size: 14px;
  word-wrap: break-word;
}

.invoice_amount {
  width: 150px;
  text-align: right;
}

.invoice_plan_name {
  color: var(--color-light-text);
}

.invoice_info_status {
  text-align: right;
}
.invoice_status {
  background: var(--color-background);
  border: 1px solid var(--color-success);
  text-transform: capitalize;
  flex: unset;
  margin-right: 2rem;
  padding: 5px 10px;
  border-radius: 8px;
  color: var(--color-success);
  flex: 1;
  width: 150px;
  text-align: center;
}
.MuiSkeleton-text:empty:before {
  content: '' !important;
}

.FTUE_container {
  display: flex;
  /* background-color: blueviolet; */
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.FTUE_container_inner {
  display: flex;
  flex-direction: column;
  /* background-color: aqua; */
  justify-content: center;
  align-items: center;
}
.FTUE_image {
  width: 40%;
}

.FTUE_image img {
  width: 100%;
}
.FTUE_user_name {
  font-size: 24px;
  margin: 10px 0;
  font-weight: 500;
}
.FTUE_msg {
  text-align: center;
  line-height: 1.7;
}

.FTUE_actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-radius: 30px;
  overflow: hidden;
}

.FTUE_card {
  padding: 20px 30px;
  margin-right: 2px;
  background-color: var(--color-primary);
  cursor: pointer;
}
.last_ftue_card {
  margin-right: 0;
}

.FTUE_card_msg {
  color: var(--color-white);
  font-weight: 500;
}

.severity_tag {
  padding-left: 0;
  font-size: 14px;
}
.MuiChip-deleteIcon {
  color: var(--color-primary) !important;
}
.MuiFormControlLabel-root {
  color: var(--color-text) !important;
}
.manual-filter .MuiFormControlLabel-root {
  color: var(--color-text) !important;
  margin-top: -5px;
}
.notificationCard .MuiFormControlLabel-root {
  margin-right: 100px;
}

.introjs-tooltip {
  border: 2px solid var(--color-primary) !important;
  background: var(--color-background) !important;
}
.introjs-tooltiptext {
  color: var(--color-text);
}
.introjs-tooltip-title {
  color: var(--color-text);
}
.introjs-button {
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
  text-shadow: none !important;
  font-weight: 500 !important;
}
.grecaptcha-badge {
  visibility: collapse !important;
}

.gg-moon,
.gg-moon::after {
  display: block;
  box-sizing: border-box;
  border-radius: 50%;
}

.gg-moon {
  overflow: hidden;
  position: relative;
  transform: rotate(-135deg) scale(var(--ggs, 1));
  width: 24px;
  height: 24px;
  border: 2px solid;
  border-bottom-color: transparent;
}

.gg-moon::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 19px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  top: 8px;
  left: 2px;
}

.gg-sun {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 24px;
  height: 24px;
  background: linear-gradient(to bottom, currentColor 4px, transparent 0)
      no-repeat 5px -6px/2px 6px,
    linear-gradient(to bottom, currentColor 4px, transparent 0) no-repeat 5px
      14px/2px 6px,
    linear-gradient(to bottom, currentColor 4px, transparent 0) no-repeat -8px 5px/6px
      2px,
    linear-gradient(to bottom, currentColor 4px, transparent 0) no-repeat 14px
      5px/6px 2px;
  border-radius: 100px;
  box-shadow: inset 0 0 0 2px;
  border: 6px solid transparent;
}

.gg-sun::after,
.gg-sun::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 24px;
  height: 2px;
  border-right: 4px solid;
  border-left: 4px solid;
  left: -6px;
  top: 5px;
}

.gg-sun::before {
  transform: rotate(-45deg);
}

.gg-sun::after {
  transform: rotate(45deg);
}

.issue_list_item {
  text-decoration: underline;
}
.issue_list_item:hover {
  text-decoration: underline;
  color: var(--color-primary);
  cursor: pointer;
}

.MuiSkeleton-root {
  background: var(--color-light-grey) !important;
}
.MuiInputBase-input.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.09) !important;
  border-radius: 6px;
}

.ReactVirtualized__Grid__innerScrollContainer {
  max-width: 100% !important;
}

.rhAdmin-subscription-msg-box {
  width: 550px;
  margin: 100px auto;
  height: 165px;
  border-radius: 8px;
  box-shadow: var(--dark-mode-box-shadow);
  padding: 11px;
  display: flex;
  align-items: center;
  background-color: var(--color-background);
  color: var(--color-text);
  justify-content: center;
}
.imag-bg {
  background: var(--color-light-grey);
  padding: 1rem;
}
.link:hover {
  text-decoration: underline;
  color: var(--color-primary);
}
.MuiTypography-h6 {
  font-weight: 700 !important;
}
.notification-severity {
  font-size: 12px;
  text-align: center;
}
.dashboard-notification-severity {
  font-size: 10px;
  text-align: center;
  font-weight: bold;
}
.security-severity {
  font-size: 11px;
  text-align: center;
}
.event-type {
  font-size: 14px;
  color: var(--color-primary);
  /* border-left: 1px solid var(--color-dark-grey); */
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
  min-height: 48px !important;
}
.MuiAccordionDetails-root {
  padding: 0 15px;
  margin-left: 30px;
  align-items: center;
  word-break: break-all;
}
.MuiAccordionSummary-root {
  padding: 0 !important;
  justify-content: start !important;
}
.MuiDivider-root {
  background: var(--color-light-grey) !important;
}
.textInput .MuiFormHelperText-root {
  background-color: var(--color-light-grey);
  margin: 0;
  padding: 3px 14px 0;
}
.attackSearchBar .MuiOutlinedInput-input {
  padding: 14px;
}
.riksTag span {
  color: var(--color-white) !important;
}
.css-26l3qy-menu {
  z-index: 102 !important;
}
.css-1nmdiq5-menu{
  z-index: 1001 !important;
}
.asset_card .css-13cymwt-control {
  min-height: 56px;
  background-color: var(--color-lighter-grey) !important;
  border-radius: 2px !important;
  
}
.asset_card .css-13cymwt-control:visited {
  min-height: 56px;
  background-color: var(--color-lighter-grey) !important;
  border-radius: 2px !important;
  
}
.asset_card .css-t3ipsp-control:hover {
  border-color: var(--color-lighter-grey) !important;
  box-shadow: 0 0 0 1px var(--color-lighter-grey) !important;
}
.asset_card .css-t3ipsp-control {
  min-height: 56px;
  background-color: var(--color-lighter-grey) !important;
  border-radius: 2px !important;
  
}
.asset_card .css-1nmdiq5-menu{
  z-index: 10001 !important;
}
.css-1s2u09g-control {
  min-height: 48px;
  background-color: var(--color-white) !important;
  border-radius: 2px !important;
  border: 1px solid var(--color-light-grey) !important;
  box-shadow: none !important;
}
.css-1pahdxg-control {
  min-height: 48px;
  background-color: var(--color-lighter-grey) !important;
  box-shadow: none !important;
  border: 1px solid var(--color-light-grey) !important;
}
.css-1pahdxg-control:hover {
  /* min-height: 48px; */
  border: 1px solid var(--color-light-grey) !important;
}
.css-26l3qy-menu {
  background-color: var(--color-lighter-grey) !important;
  color: var(--color-text) !important;
}
.css-26l3qy-menu div div {
  background-color: var(--color-lighter-grey) !important;
  color: var(--color-text) !important;
}
.css-26l3qy-menu div div:hover {
  background-color: var(--color-highlightedBG) !important;
  color: var(--color-text) !important;
}
.risk:hover {
  /* text-decoration: underline; */
  background-color: var(--hover-color) !important;
  color: #fff !important;
}

.scan-page-tab {
  border-bottom: none !important;
}
.scan-page .MuiTab-textColorPrimary {
  background-color: var(--color-highlightedBG) !important;
}

.rh-add-action .MuiFormHelperText-containede {
  margin-left: 0;
}

.ace_print-margin {
  visibility: hidden !important;
}
.ace_gutter-cell {
  display: flex;
  justify-content: center;
}
.ace_gutter {
  background: var(--color-background) !important;
  border-right: 0.5px solid #949494;
}

.MuiAccordion-root:before {
  display: none;
}

.MuiAccordion-root {
  background: var(--color-background) !important;
}

.MuiAccordionSummary-content {
  margin: 0;
  width: max-content;
  flex-grow: 0 !important;
}
.MuiAccordionSummary-root.Mui-expanded {
  min-height: fit-content !important;
  width: fit-content;
  justify-content: start !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  min-height: fit-content !important;
}

.MuiAccordionDetails-root {
  display: flex !important;
  padding: 0 0 10px !important;
  margin-left: 0;
  flex-wrap: wrap;
  margin-left: 139px;
  width: 64%;
}
.MuiAccordionSummary-expandIcon {
  transform: none !important;
}

.MuiAccordion-root.Mui-expanded {
  min-width: 100%;
}
.MuiDialogContent-dividers.MuiDialogContent-root.metadata {
  padding: 0 24px !important;
}

/* Table Layout css */
.tableLayout {
  overflow: scroll;
  /* width: 100%; */
  height: 75vh;
  /* border: 1px solid var(--color-light-grey) !important; */
}
.tableLayout table {
  overflow-x: auto;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
}
.tableLayout thead th {
  position: sticky;
  top: -1px;
  z-index: 99;
  background: #fbfbfc !important;
}
.tableLayout thead {
  border-bottom: 1px solid var(--color-stroke) !important;
  height: 62px;
}
.tableLayout table th {
  color: var(--color-text);
  text-align: left;
  border-bottom: 1px solid var(--color-stroke) !important;
  border-top: 1px solid var(--color-stroke) !important;
  padding: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  vertical-align: top;
  max-height: 40px;
  min-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  background: #fbfbfc !important;
  border-right: none !important;
  filter: drop-shadow(3px 2px 4px rgba(0, 0, 0, 0.08));
}

.tableLayout table td {
  color: #4d4d4d;
  text-align: left;
  border-bottom: 1px solid var(--color-stroke) !important;
  padding: 9px;
  font-size: 14px;
  font-weight: 500;
  height: 62px;
  /* padding-top: 0px;
  padding-bottom: 0px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  background: var(--color-background) !important;
  /* -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden; */
}
.ellipsis {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  line-height: 20px;
}
.tableLayout .headerCell {
  /* margin-bottom: 10px; */
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  padding-top: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tableLayout .headerCell-center {
  /* margin-bottom: 10px; */
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  padding-top: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.th-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  line-height: 24px;
}
/* .tableLayout .headerCell {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
/* .tableLayout .headerCell .icon {
  display: none;
}
.tableLayout .headerCell:hover .icon {
  display: block;
} */

.tableLayout::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

.tableLayout::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}
.tableLayout::-webkit-scrollbar:horizontal
{
	height: 5px;
	background-color: #F5F5F5;
}

.tableLayout::-webkit-scrollbar-thumb:vertical
{
	border-radius: 10px;
	background: #7a99d9;
}
.tableLayout::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
  background: #7a99d9;

}
.scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

.scroll::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}
.scroll::-webkit-scrollbar:horizontal
{
	height: 5px;
	background-color: #F5F5F5;
}

.scroll::-webkit-scrollbar-thumb:vertical
{
	border-radius: 10px;
	background: #7a99d9;
}
.scroll::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
  background: #7a99d9;

}

.tableLayout .fix {
  position: sticky;
  position: -webkit-sticky;
  
  /* background: var(--color-background) !important; */
}
thead th.columnResizer {
  border: none !important;
  border-bottom: 1px solid vaR(--color-light-grey) !important;
  border-top: 1px solid vaR(--color-light-grey) !important;
  z-index: 99;
  background: #fbfbfc !important;
  width: 4px !important;
  padding: 0 !important;
  position: sticky;
  top: 0px;
}
thead th.columnResizer:hover {
  background: var(--color-stroke) !important;
}
thead th.columnResizer:active {
  background: var(--color-stroke) !important;
}
thead td.columnResizer {
  border: none !important;
  border-bottom: 1px solid vaR(--color-light-grey) !important;
  border-top: 1px solid vaR(--color-light-grey) !important;
  z-index: 99;
  background: #fbfbfc !important;
  width: 4px !important;
  padding: 0 !important;
  position: sticky;
  top: 0px;
}
thead td.columnResizer:hover {
  background: var(--color-stroke) !important;
}
thead td.columnResizer:active {
  background: var(--color-stroke) !important;
}
.tableLayout table .fix {
  left: -1px;
  width: 180px;
  /* filter: drop-shadow(3px 0px 4px rgba(0, 0, 0, 0.08)); */
  z-index: 1;
}
.tableLayout table .MuiInputBase-input {
  padding: 10.5px;
}
.tableLayout table .MuiChip-sizeSmall {
  height: 30px;
}
.tableLayout table thead th .MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--color-success) !important;
}
.tableLayout table thead th .MuiCheckbox-colorSecondary {
  color: var(--color-light-text) !important;
}
.tableLayout table thead th .MuiOutlinedInput-inputMarginDense {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.tableLayout table .fixHead {
  position: sticky;
  left: -1px;
  top: -1px;
  z-index: 100;
  width: 180px;
  /* background: #f3f4f9; */
  /* filter: drop-shadow(3px 0px 4px rgba(0, 0, 0, 0.08)); */
}
.text-elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
}

.MuiMenu-paper {
  max-height: 300px !important;
}
.severity {
  padding: 4px 10px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 25px;
  width: 117px;
  text-align: center;
}
.moderate.severity {
  background: var(--color-moderate100);
  border: 1px solid var(--color-moderate600);
  color: var(--color-moderate600);
}
.high.severity {
  background: var(--color-warning100);
  border: 1px solid var(--color-warning);
  color: var(--color-warning);
}
.low.severity {
  background: var(--color-success100);
  border: 1px solid var(--color-success);
  color: var(--color-success);
}
.critical.severity {
  background: var(--color-danger100);
  border: 1px solid var(--color-danger);
  color: var(--color-danger);
}
.active.status {
  background: var(--color-success100);
  border: 1px solid var(--color-success);
  color: var(--color-success);
  font-size: 14px;
  width: 82px;
}
.mitigated.status {
  background: var(--color-warning100);
  border: 1px solid var(--color-warning);
  color: var(--color-warning);
  font-size: 14px;
  width: 97px;
}
.not.found.status {
  background: var(--color-warning100);
  border: 1px solid var(--color-warning);
  color: var(--color-warning);
  font-size: 14px;
  width: 97px;
}
.muted.status {
  background: var(--color-danger100);
  border: 1px solid var(--color-danger);
  color: var(--color-danger);
  font-size: 14px;
  width: 82px;
}
.severity.info {
  background-color: var(--color-light-info);
  color: var(--color-info);
  border: 1px solid var(--color-info);
}
.severity.none {
  background-color: var(--color-light-info);
  color: var(--color-info);
  border: 1px solid var(--color-info);
}
.tableLayout .MuiInputLabel-outlined {
  display: block;
  transform: translate(14px, 13px) scale(1);
}
.tableLayout .MuiInputLabel-outlined.MuiInputLabel-shrink {
  display: none;
}
.issue-tracker .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(15px, 0px) scale(0.75);
  background: #fff;
}

.pro-sidebar-inner {
  background: var(--color-blueBG) !important;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul {
  padding: 16px !important;
}
.pro-sidebar .pro-menu {
  padding-top: 0px !important;
}

.pro-sidebar .pro-menu .pro-menu-item.active:hover {
  color: var(--color-white) !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: var(--color-white) !important;
}

.pro-item-content.active {
  color: var(--color-white) !important;
  background-color: var(--color-primary) !important;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background-color: var(--color-blueBG) !important;
}
.popper-inner {
  background-color: var(--color-blueBG) !important;
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  overflow-y: unset !important;
  padding-left: 0px !important;
}
/* .pro-sidebar-content::-webkit-scrollbar {
  width: 4px !important;;
  background: var(--color-light-grey) !important;
}
.pro-sidebar-content::-webkit-scrollbar-thumb {
  background: #888 !important;
}
::-webkit-scrollbar-track {
  height: 3px !important;
  width: 3px !important;
} */

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  width: 100%;
  padding-left: 0 !important;
}
.pro-sidebar.collapsed .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  margin-left: 15px;
  margin-right: 25px;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  margin-right: 0 !important;
}
.pro-sidebar.collapsed
  .pro-menu
  > ul {
    padding: 16px 0 16px !important;
  }
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item:hover {
  background-color: #323238 !important;
  height: 65%;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu.Collapsed
  .pro-inner-list-item
  .pro-inner-item:hover {
  background-color: #f7f7f7 !important;
  color: var(--color-text) !important;
  height: 65%;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu.Expanded
  .pro-inner-list-item
  .pro-inner-item {
  width: 100%;
  padding: 8px 30px 8px 60px !important;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu.Collapsed
  .pro-inner-list-item
  .pro-inner-item {
  width: 100%;
  padding: 8px 30px 8px 10px !important;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding: 0 !important;
  background: var(--color-blueBG);
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  font-weight: 500;
  font-size: 14px;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  min-width: 10px !important;
}

.emails___menu.css-26l3qy-menu {
  z-index: 100 !important;
  position: absolute;
}

.modal .MuiDialog-paper {
  overflow-y: clip;
  border-radius: 4px !important;
}

.Dropdown-control {
  color: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
}

.Dropdown-arrow {
  border-color: var(--color-primary) transparent transparent !important;
}

.checkbox-check .MuiSvgIcon-root {
  width: 20px;
  height: 20px;
}

.introjs-fixedTooltip {
  position: absolute !important;
}
.tabScroll .MuiTabs-fixed {
  overflow: auto !important;
}
.tabScroll ::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: var(--color-light-grey) !important;
}
.tabScroll ::-webkit-scrollbar-thumb {
  background: #888 !important;
}
::-webkit-scrollbar-track {
  height: 3px !important;
  width: 3px !important;
}

.MuiAutocomplete-root .MuiChip-root {
  background: transparent !important;
  height: auto !important;
}
.dropdown-list .MuiIconButton-root {
  padding: 0 !important;
}

.category {
  padding: 4px 10px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 25px;
  width: fit-content;
  text-align: center;
}

.issueList .MuiTabs-scrollable .MuiTab-textColorPrimary.Mui-selected {
  color: #000 !important;
  opacity: 1 !important;
  font-weight: 600 !important;
  background-color: var(--color-bodyBG) !important;
}
.issueList .MuiTabs-indicator {
  display: none !important;
}
.react-tel-input {
  margin-top: 16px !important;
}
.react-tel-input .form-control {
  border-radius: 2px !important;
  width: 100% !important;
}


.chart__bubble {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.rotated-half-circle {
  width: 0;
	height: 0;
	border-left: 50px solid transparent;
	border-right:0px solid transparent;
	border-top: 50px solid #555;
    transform: rotate(-90deg);
}

.bubble-chart-container .bubble-chart-text .bubble-label {
  color: var(--color-text) !important;
}


.list__head_row {
  border-radius: 0px 12px 12px 0px;
background: var(--primary-primary-20, #FAFBFE);
padding: 12px 20px;
color: var(--grays-gray-80, #1A1A1A);
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 21px; /* 150% */
letter-spacing: 0.035px;
width: 100%;
text-align: left;
}

.filterTab .MuiSvgIcon-root {
  width: 20px !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  flex-wrap: nowrap !important;
}

.table-menu .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded{
  max-height: 100% !important;
}

.blacklistCard .css-13cymwt-control {
width: 350px !important;
}
.blacklistCard .css-t3ipsp-control {
width: 350px !important;
}

.userList .InovuaReactDataGrid__cell__content{
overflow: visible !important;
}

.blacklistCard .Dropdown-control {
  width: 160px;
}


.total_assets_table {
  position: relative;
  overflow: hidden;
}


.MuiOutlinedInput-inputMultiline::-webkit-scrollbar-track {
  background: var(--color-light-grey) !important;
}
.MuiOutlinedInput-inputMultiline::-webkit-scrollbar {
  height: 8px !important;
  width: 5px !important;
  background: transparent !important;
}
.MuiOutlinedInput-inputMultiline::-webkit-scrollbar-thumb {
  height: 8px !important;
  border-radius: 5px;
  background: #ccc !important;
}
.MuiOutlinedInput-inputMultiline::-webkit-scrollbar-corner {
  background: var(--color-light-grey) !important;
}
.dropdown .MuiPopover-paper.MuiPaper-root.MuiPopover-paper.MuiPaper-rounded {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

.top-round {
  position: absolute;
  left: 100%;
  top: 0%;
  background: linear-gradient(135deg, var(--color-blueBG) 43%,transparent 43%);
}
.top-round-back {
  width: 40px;
  height: 40px;
  clip-path: circle(100% at 100% 99%);
  background: linear-gradient(135deg, var(--color-bodyBG) 43%,transparent 43%);
}

.checkbox-menu .MuiMenuItem-root{
  font-size: 13px !important;
  /* max-height: auto; */
  padding: 0 5px !important;
}
.checkbox-menu  .MuiMenuItem-root .MuiSvgIcon-root{
  width: 20px !important;
}
.checkbox-menu  .MuiMenuItem-root .MuiCheckbox-root{
  padding: 5px !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item{
  padding: 8px 20px 8px 20px !important;
}
.pro-sidebar.collapsed .pro-menu .pro-menu-item > .pro-inner-item{
  padding: 8px 35px 8px 20px !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper{
  right: 10px !important;
}

.MuiDrawer-paperAnchorRight{
  left: auto;
    right: 10px !important;
    border-radius: 10px;
    bottom: 11px;
    top:10px !important;
    height:98% !important;
}

.summary, .summary p{
font-size: 14px;
text-align: justify;
color: #5f6368;
}
.summary a{
font-size: 14px;
text-align: justify;
color: #5f6368;
}

.title_elipsis{
  width:100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    background:#fff;
}
.summary_elipsis{
  width:100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    background:#fff;
}
.controls{
  display: flex;
  flex-direction: column;
}
.zoomIcon {
  text-align: center;
  cursor: pointer;
  background: transparent;
  border: none;
}


.session{
  padding: 40px 40px 12px;
}
.session .container {
  text-align: center;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 40px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
}
.session .icon{
  text-align: center;
}
.session .icon img {
  width: 80px;
  margin-bottom: 20px;
}

.session h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.session p {
  color: #666;
  margin-bottom: 30px;
}

.session .refresh-button {
  background-color: var(--color-primary);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  float: inline-end;
  transition: background-color 0.3s ease;
}

.session .refresh-button:hover {
  background-color: var(--color-primary);
}


.status-code {
  padding: 5px 20px;
  margin: 10px 0;
  border-radius: 30px;
  font-size: 14px;
  color: white;
  width: fit-content;
  line-height: 30px;
  font-weight: 600;
}

.status-2xx {
  background-color: green;
}

.status-3xx {
  background-color: blue;
}


.status-5xx {
  background-color: red;
}

/* Custom color for amber */
.status-4xx {
  background-color: #ffbf00;
}

.drawer .MuiTabs-root{
  padding: 0;
  background-color: var(--color-bodyBG);
}
.drawer .MuiTabs-flexContainer{
  justify-content: space-between;
  /* border-bottom: 2px solid var(--color-primary); */
}
.drawer .MuiTabs-flexContainer .MuiButtonBase-root{
  background-color: var(--color-light-grey) !important;
  color: var(--color-primary) !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  flex: 1;

}
.drawer .MuiTabs-flexContainer .Mui-selected{
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.drawer .MuiTab-wrapper{
  display: flex !important;
  align-items: center !important;
  font-size: 14px !important;
  flex-direction: row !important;
}
.drawer .MuiTab-labelIcon{
  min-height: 48px !important;
}

.drawer .MuiTab-textColorPrimary.Mui-disabled {
  color: rgba(0, 0, 0, 0.54) !important;
}

.instruction-container {
  max-width: 800px;
  margin: 0 auto;
  height: 83vh;
  overflow-y: scroll;
  padding: 20px;
  font-family: Arial, sans-serif;
  border-radius: 10px;
  margin-top: 12px;
  margin-right: 8px;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

.instruction-container h1 {
  text-align: center;
  color: #333;
}

.instruction-step {
  margin-bottom: 18px;
}

.instruction-step h2 {
  color: #0056b3;
  font-size: 20px;
  margin-bottom: 10px;
}
.instruction-step h4 {
  color: var(--color-light-text);
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
}

.colored-dot {
  width: 10px;                
  height: 10px;               
  border-radius: 50%;         
  display: inline-block;  
}

.instruction-step ol {
  list-style-type: decimal;
  padding-left: 20px;
}
.instruction-step ul {
  padding-left: 20px;
}
.instruction-step ul li {
  font-weight: bold;
}

.instruction-step li {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

.instruction-step a {
  color: #0056b3 !important;
  text-decoration: none;
  font-weight: 500;
}

.instruction-step a:hover {
  text-decoration: underline;
}

.instruction-step code {
  background-color: #eef;
  padding: 2px 4px;
  border-radius: 3px;
  font-family: 'Courier New', Courier, monospace;
}

.instruction-step strong {
  color: #333;
}
.scroring_banner {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  flex: 1;
  margin-right: 28px;
}
.scroring_banner_graph {
  display: flex;
  align-items: center;
}
.scroring_count {
  border-radius: 16px;
  color: #fff;
  padding: 12px 24px;
  text-align: center;
  margin-right: 24px;
}
.scroring_count_chip {
  border-radius: 6px;
  color: #fff;
  padding: 4px 8px;
  text-align: center;
  margin-right: 24px;
  font-size: 12px;
  font-weight: 600;
}

.no-arrows input[type=number] {
  -moz-appearance: textfield;
}
.no-arrows input::-webkit-outer-spin-button,
.no-arrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.scan-tooltip .MuiTooltip-tooltip {
  font-size: 14px !important;
}

.MuiToggleButton-sizeSmall {
  padding: 2px 7px !important;
}
.MuiToggleButton-root.Mui-selected {
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
}