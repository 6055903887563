/* @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap'); */

/* montserrat-regular - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Noto_Sans/NotoSans-Regular.ttf'); /* IE9 Compat Modes */
  src: local('Noto Sans Regular'), local('Montserrat-Regular'),
    url('./assets/fonts/Noto_Sans/NotoSans-Regular.ttf')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/Noto_Sans/NotoSans-Regular.ttf')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/Noto_Sans/NotoSans-Regular.ttf') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/Noto_Sans/NotoSans-Regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/montserrat-v14-latin-regular.svg#Montserrat')
      format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* montserrat-300 - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./assets/fonts/Noto_Sans/NotoSans-Light.ttf'); /* IE9 Compat Modes */
  src: local('Noto Sans Light'), local('Montserrat-Light'),
    url('./assets/fonts/Noto_Sans/NotoSans-Light.ttf')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/Noto_Sans/NotoSans-Light.ttf')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/Noto_Sans/NotoSans-Light.ttf') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/Noto_Sans/NotoSans-Light.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/montserrat-v14-latin-300.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./assets/fonts/Noto_Sans/NotoSans-Medium.ttf'); /* IE9 Compat Modes */
  src: local('Noto Sans Medium'), local('Montserrat-Medium'),
    url('./assets/fonts/Noto_Sans/NotoSans-Medium.ttf?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/Noto_Sans/NotoSans-Medium.ttf')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/Noto_Sans/NotoSans-Medium.ttf') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/Noto_Sans/NotoSans-Medium.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/montserrat-v14-latin-500.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./assets/fonts/Noto_Sans/NotoSans-Bold.ttf'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('./assets/fonts/Noto_Sans/NotoSans-Bold.ttf?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/Noto_Sans/NotoSans-Bold.ttf')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/Noto_Sans/NotoSans-Bold.ttf') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/Noto_Sans/NotoSans-Bold.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/montserrat-v14-latin-700.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
body {
  margin: 0;
  font-family: Noto Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: var(--color-bodyBG); */
}

code {
  font-family: Noto Sans, source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}

* {
  margin: 0;
  padding: 0;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
/* ::-webkit-scrollbar-thumb {
  background: var(--color-light-text);
} */

.globle-description-text div {
  white-space: initial !important;
  display: -webkit-box;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}



.loading {
  font-size: 48px;
  font-weight: 800;
  text-align: center;
  display: flex;
  letter-spacing: 4px;
  color: var(--color-grey);
  line-height: 44px;
}
.loading span {
  display: inline-block;
}
.borderSpanLoader{
  border-top: 5px solid var(--color-grey);
  /* border-bottom: none; */
}
.borderLoader{
  border-bottom: 5px solid var(--color-grey);

}

/* code #1 */
.loading01 span {
  animation: loading01 1.4s infinite alternate;
}
.loading01 span:nth-child(1) {
  animation-delay: 0s;
}
.loading01 span:nth-child(2) {
  animation-delay: 0.1s;
}
.loading01 span:nth-child(3) {
  animation-delay: 0.2s;
}
.loading01 span:nth-child(4) {
  animation-delay: 0.3s;
}
.loading01 span:nth-child(5) {
  animation-delay: 0.4s;
}

@keyframes loading01 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
